<template>
  <div class="description-esg">
    <div class="description-esg__item">
      <h2>{{ $t('project-name') }}:</h2>
      <p>{{ getEsgId?.name || $t('empty') }}</p>
    </div>
    <div class="description-esg__item">
      <h2>{{ $t('project-category') }}:</h2>
      <p>{{ getEsgId.category?.name  || $t('empty')  }}</p>
    </div>
    <div class="description-esg__item">
      <h2>{{ $t('project-type') }}:</h2>
      <p>{{ getEsgId.type?.name || $t('empty')  }}</p>
    </div>
    <div class="description-esg__item">
      <h2>{{ $t('project-subtype') }}:</h2>
      <p>{{ getEsgId.subtype?.name || $t('empty')  }}</p>
    </div>
    <div class="description-esg__item">
      <h2>{{ $t('taxonomy-type') }}:</h2>
      <p>{{ getTaxonomy }}</p>
    </div>
    <div class="description-esg__item column">
      <h2>{{ $t('description') }}:</h2>
      <p v-if="showMore">{{ getEsgDescription }}</p>
      <p v-if="!showMore">{{ getEsgId?.description }}</p>
      <p
          v-if="getEsgId?.description?.length > 200"
          style="color: #CE2121;cursor: pointer;"
          @click="showMore = !showMore"
      >
        {{ showMore ?  $t('more') : $t('collapse') }}
      </p>
    </div>
    <div class="upload" v-if="getEsgId.filesSrc.length">
      <p class="upload__text">
        {{ $t('documents') }}:
      </p>
      <div class="upload__wrap">
        <a
            v-for="(item, key) in getEsgId.filesSrc"
            :href="item.fileSrc"
            target="_blank"
            style="display: flex"
            :key="key"
            class="upload__wrap-file"
        >
          <img
              src="@/assets/svg/icon-docs-grey.svg"
              alt=""
          />
          <div class="upload__wrap-file-info">
            <p>{{ item.name || item.originalname }}</p>
            <span>{{ getFullDate(item.createdAt || new Date()) }}</span>
          </div>
        </a>
      </div>
    </div>
    <div class="description-esg__actions" v-if="getEsgId.status === 'Moderation' && (getUser?.role === 'Moderator' || getUser?.role === 'Admin')">
      <ui-button @click="$emit('accept')">{{ $t('accept') }}</ui-button>
      <ui-button color="error" @click="$emit('reject')">{{ $t('reject') }}</ui-button>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";
import {getFullDate} from "../../../../utils/general";
import {mapGetters} from "vuex";

export default {
  name: "DescriptionProblemsEsg",
  components: {UiButton},

  data () {
    return {
      category: '',
      type: '',
      subtype: '',
      descriptionCount: 200,
      showMore: true,
      taxonomy: [
        {name: this.$t('green-taxonomy'), value: 'green'},
        {name: this.$t('social-taxonomy'), value: 'social'},
      ],
    }
  },

  methods: {
    getFullDate,
  },

  computed: {
    ...mapGetters(['getUser', 'getEsgId']),

    getEsgDescription () {
      let info = this.getEsgId?.description?.length > this.descriptionCount ? this.getEsgId.description.slice(0, this.descriptionCount) + '...' : this.getEsgId?.description?.length ? this.getEsgId.description : this.$t('empty')
      return info
    },

    getTaxonomy () {
      return this.taxonomy[0].value === this.getEsgId.taxonomyType ? this.taxonomy[0].name : this.taxonomy[1].value === this.getEsgId.taxonomyType ? this.taxonomy[1].name : this.$t('empty')
    }
  }
}
</script>

<style lang="scss" scoped>
.description-esg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-top: 35px;

  &__item {
    display: flex;
    gap: 10px;

    @media(max-width: 600px) {
      flex-wrap: wrap;
    }

    &.column {
      display: flex;
      flex-direction: column;
      gap: 10px;

      p {
        max-width: 630px;
        word-wrap: break-word;
        display: inline-block;
      }
    }

    h2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #9A9A9A;
      white-space: nowrap;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #343432;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      gap: 5px;

      @media (max-width: 1200px) {
        white-space: normal;
      }
    }

    span {
      display: flex;
      align-items: center;
      gap: 5px;

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        background: #9A9A9A;
        border-radius: 50%;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 30px;
  }
}
.upload {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
  }

  &__wrap {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: auto;
    right: 1rem;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    max-width: 600px;

    &::-webkit-scrollbar {
      width: 20px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #9A9A9A;
      border-radius: 10px;
    }

    &-btn {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E21F1F;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }

    input {
      display: none;
    }


    &-file {
      position: relative;
      flex-direction: column;
      align-items: center;
      max-width: 116px;
      min-width: 114px;
      gap: 10px;

      &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #1B1A1F;
          word-break: break-all;
        }

        span {
          font-weight: 400;
          font-size: 10px;
          line-height: 112.7%;
          color: #BFBDBD;
          width: 58px;
          text-align: center;
        }
      }

      &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 24px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #E21F1F;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
</style>
