<template>
  <div class="check-types">
    <ui-input
        :label="$t('project-name')"
        v-model="info.projectName"
        @input="$emit('sendInfo', info)"
        :error="$v.info.projectName.$anyError"
        :error-text="$t('specify-project-name')"
    />
    <ui-select
        :label="$t('project-category')"
        :options="getEsgCategories.data"
        v-model="info.category"
        @input="$emit('sendInfo', info)"
        :error="$v.info.category.$error"
        :error-text="$t('specify-project-category')"
    />
    <ui-select
        :label="$t('project-type')"
        :options="getEsgTypes.data"
        v-model="info.type"
        @input="$emit('sendInfo', info)"
        :error="$v.info.type.$error"
        :error-text="$t('specify-project-type')"
    />
    <ui-select
        :label="$t('project-subtype')"
        :options="getEsgSubtypes.data"
        v-model="info.subtype"
        @input="$emit('sendInfo', info)"
        :error="$v.info.subtype.$error"
        :error-text="$t('specify-project-subtype')"
    />
    <ui-select
        :label="$t('taxonomy-type')"
        :options="typeTaxesOptions"
        v-model="info.typeTaxes"
        @input="$emit('sendInfo', info)"
        :error="$v.info.typeTaxes.$error"
        :error-text="$t('specify-taxonomy-type')"
    />
    <ui-textarea
        height="80"
        radius="12"
        :label="$t('description')"
        v-model="info.description"
        @input="$emit('sendInfo', info)"
        :error="$v.info.description.$anyError"
        :error-text="$t('fill-in-the-field')"
        :isResize="true"
    />
    <div class="upload">
      <p :class="['upload__text', { error: $v.info.documentFile?.$error }]">
        {{ $t('documents') }}
      </p>
      <div class="upload__wrap">
        <label>
          <input type="file" ref="file" @input="uploadDocument" accept="image/*" >
          <span class="upload__wrap-btn">
            <img src="@/assets/svg/icon-plus-white.svg" alt="" />
          </span>
        </label>
        <a
            v-for="(item, key) in info.documentSrc"
            :href="item.fileSrc"
            target="_blank"
            style="display: flex"
            :key="key"
            class="upload__wrap-file"
        >
          <img
              src="@/assets/svg/icon-docs-grey.svg"
              alt=""
          />
          <div class="upload__wrap-file-close" @click="deleteFile($event, item.file)">
            <img
                src="@/assets/svg/close/white.svg"
                alt=""
            />
          </div>
          <div class="upload__wrap-file-info">
            <p>{{ item.name || item.originalname }}</p>
            <span>{{ getFullDate(item.createdAt || new Date()) }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions, mapGetters} from "vuex";
import UiInput from "@/components/ui/UiInput.vue";
import UiTextarea from "@/components/ui/UiTextarea.vue";
import {required} from "vuelidate/lib/validators";
import {getFullDate} from "../../../../utils/general";

export default {
  name: "EsgCheckTypes",
  components: {UiTextarea, UiInput, UiSelect},

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  validations() {
    return {
      info: {
        projectName: {
          required,
        },
        category: {
          required,
        },
        type: {
          required,
        },
        subtype: {
          required,
        },
        typeTaxes: {
          required,
        },
        description: {
          required,
        },
        documentFile: {
          required
        },
      }
    }
  },


  data () {
    return {
      typeTaxesOptions: [
        {name: this.$t('green'), value: 'green'},
        {name: this.$t('social'), value: 'social'},
      ],
      info: {
        category: null,
        type: null,
        subtype: null,
        typeTaxes: null,
        projectName: '',
        description: '',
        documentFile: [],
        documentSrc: [],
      },
    }
  },

  computed: {
    ...mapGetters(['getEsgId', 'getEsgCategories', 'getEsgTypes', 'getEsgSubtypes']),
  },

  methods: {
    getFullDate,
    ...mapActions(['esgCategories', 'esgTypes', 'esgSubtypes', 'reqUpload']),
    uploadDocument({ target }) {
      this.reqUpload({
        file: target.files[0],
        type: "document",
      }).then((res) => {
        this.info.documentFile.push(res.data.filename)
        this.info.documentSrc.push(res.data)
        this.$refs.file.value = null
      })
      this.$emit('sendInfo', this.info)
    },

    deleteFile(e, fileName) {
      e.preventDefault();
      this.info.documentFile = this.info.documentFile.filter((elem) => elem !== fileName);
      this.info.documentSrc = this.info.documentSrc.filter((elem) => elem.file !== fileName);
      this.$emit('sendInfo', this.info)
    },
  },

  mounted () {
    this.esgCategories()
    this.esgTypes()
    this.esgSubtypes()
    if (this.getEsgId.status === 'Draft') {
      this.info.category = this.getEsgId.category
      this.info.type = this.getEsgId.type
      this.info.subtype = this.getEsgId.subtype
      this.info.projectName = this.getEsgId.name
      this.info.description = this.getEsgId.description
      this.info.documentFile = this.getEsgId.files || []
      this.info.documentSrc = this.getEsgId.filesSrc || []
      this.typeTaxesOptions.forEach(el => {
        if (el.value === this.getEsgId.taxonomyType) {
          this.info.typeTaxes = el
        }
      })
      this.$emit('sendInfo', this.info)
    }
  }
}
</script>

<style lang="scss" scoped>
.check-types {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding: 40px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #FFFFFF;

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }

  ::v-deep .ui-select__label {
    margin: 0 0 10px 0;
    color: #1B1A1F;
    font-size: 14px;
    font-weight: 400;
  }
}
::v-deep .ui-input {
  &__label {
    color: #1B1A1F;
    font-size: 14px;
    font-weight: 400;
    margin-left: 0;
  }
}
::v-deep .ui-textarea {
  &__label {
    color: #1B1A1F;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-left: 0 !important;
  }
}



.upload {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__text {
    color: #1B1A1F;
    font-size: 14px;
    font-weight: 400;

    &.error {
      color: #E21F1F;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: auto;
    right: 1rem;
    width: 100%;
    height: 100%;
    padding: 10px 0;

    &::-webkit-scrollbar {
      width: 20px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #9A9A9A;
      border-radius: 10px;
    }

    &-btn {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E21F1F;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }

    input {
      display: none;
    }


    &-file {
      position: relative;
      flex-direction: column;
      align-items: center;
      max-width: 116px;
      min-width: 114px;
      gap: 10px;

      &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #1B1A1F;
          word-break: break-all;
        }

        span {
          font-weight: 400;
          font-size: 10px;
          line-height: 112.7%;
          color: #BFBDBD;
          width: 58px;
          text-align: center;
        }
      }

      &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 24px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #E21F1F;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
</style>
